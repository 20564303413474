exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-the-a-team-1st-team-tsx": () => import("./../../../src/pages/the-a-team/1st-team.tsx" /* webpackChunkName: "component---src-pages-the-a-team-1st-team-tsx" */),
  "component---src-pages-the-a-team-index-tsx": () => import("./../../../src/pages/the-a-team/index.tsx" /* webpackChunkName: "component---src-pages-the-a-team-index-tsx" */),
  "component---src-pages-the-a-team-substitutes-tsx": () => import("./../../../src/pages/the-a-team/substitutes.tsx" /* webpackChunkName: "component---src-pages-the-a-team-substitutes-tsx" */),
  "component---src-pages-the-advisors-tsx": () => import("./../../../src/pages/the-advisors.tsx" /* webpackChunkName: "component---src-pages-the-advisors-tsx" */),
  "component---src-pages-the-doers-tsx": () => import("./../../../src/pages/the-doers.tsx" /* webpackChunkName: "component---src-pages-the-doers-tsx" */),
  "component---src-pages-thedoers-tsx": () => import("./../../../src/pages/thedoers.tsx" /* webpackChunkName: "component---src-pages-thedoers-tsx" */)
}

